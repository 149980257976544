import './secao-acesso-rapido.scss'
import {Col, Container, Row} from 'react-bootstrap';
import {Facebook, Instagram} from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {COLORS} from '../../../temas/cores';
import {useEffect, useState} from 'react';
import {gestor} from '../../../servicos/gestor';

export default function SecaoAcessoRapido() {

    type SecaoAcessoRapidoType =
        {
            loading: boolean,
            erroAoCarregar: boolean,
            dados: {
                numero?: string,
                email?: string,
                local?: string,
                linkInstagram?: string,
                linkFacebook?: string
            }
        };

    const [estado, setEstado] = useState<SecaoAcessoRapidoType>({
        loading: true,
        erroAoCarregar: false,
        dados: {}
    })

    useEffect(() => {

        gestor.getInformacoes().then(retorno => {

            if (retorno.erro) {
                estado.erroAoCarregar = true;
                setEstado({...estado});
            }

            estado.dados = {
                local: retorno.address,
                numero: retorno.phones?.at(0)?.phone,
                email: retorno.email,
                linkFacebook: retorno.social_media?.filter((social) => social.name === 'facebook')?.at(0)?.link,
                linkInstagram: retorno.social_media?.filter((social) => social.name === 'instagram')?.at(0)?.link
            }
            estado.loading = false;
            setEstado({...estado});
        });

    }, []);

    if (estado.erroAoCarregar) {
        return null;
    }

    return (
        <section id="SecaoAcessoRapido">
            <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
                <Container>
                    <Row>

                        {estado.loading ?
                            <Col lg={11} className='containerInformacoesEsquerdo'>
                                {/* <Skeleton width={700} height='20px' /> */}
                            </Col>
                            :
                            <Col lg={12} className='containerInformacoesEsquerdo'>

                                {/* {estado.dados?.numero ?
                  <a href={`tel:+${estado.dados?.numero}`} title={estado.dados?.numero} className='containerInformacoe link'>
                    <Telephone className='icone' size={20} />
                    <p className='texto'>{estado.dados?.numero}</p>
                  </a> : null}

                {estado.dados?.email ?
                  <a href={`mailto:${estado.dados?.email}`} title={estado.dados?.email} className='containerInformacoe  link'>
                    <Envelope className='icone' size={20} />
                    <p className='texto'>{estado.dados?.email}</p>
                  </a> : null}

                {estado.dados?.local ?
                  <Link to="nossas-unidades" title="nossas unidades" className='containerInformacoe  link'>
                    <PinMap className='icone' size={20} />
                    <p className='texto'>{estado.dados?.local}</p>
                  </Link> : null} */}

                            </Col>}

                        {estado.loading ?
                            <Col lg={12} className='containerInformacoesDireito'>
                                <Skeleton width={300} height='20px'/>
                            </Col>
                            :
                            <Col lg={12} className='containerInformacoesDireito'>

                                {estado.dados?.linkInstagram ?
                                    <a href={estado.dados?.linkInstagram} target='#' title="instagram"
                                       className='containerInformacoe'>
                                        <Instagram className='icone' size={20}/>
                                    </a> : null}

                                {estado.dados?.linkFacebook ?
                                    <a href={estado.dados?.linkFacebook} target='#' title="facebook"
                                       className='containerInformacoe'>
                                        <Facebook className='icone' size={20}/>
                                    </a> : null}

                                {estado.dados?.linkInstagram || estado.dados?.linkFacebook ?
                                    <hr className='barra'/> : null}

                                <Link to="trabalhe-conosco" title='trabalhe conosco' className='link'>
                                    <p className='texto'>Trabalhe Conosco</p>
                                </Link>

                                <hr className='barra'/>

                                <Link to={'/relatorio-igualdade-salarial-homens-e-mulheres.pdf'}
                                      target="_blank"
                                      title='Relatório de Igualdade Salarial'
                                      className='link'>
                                    <p className='texto'>Relatório de Igualdade Salarial</p>
                                </Link>
                            </Col>}

                    </Row>
                </Container>
            </SkeletonTheme>
        </section>
    );
}
