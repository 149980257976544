import './secao-banner.scss'
import {Container} from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import {useEffect, useState} from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import {COLORS} from '../../../temas/cores';
import {FuncoesAuxiliares} from '../../../utilidades/funcoesAuxiliares';
import {Link, useLocation} from 'react-router-dom';
import {gestor} from '../../../servicos/gestor';
import bannerRevolucaoRecapagem from '../../../arquivos/gif/banner-revolucao-recapagem.gif'

export type comportamentoSecaoBanner =
    'home' |
    'institucional' |
    'contato' |
    'blog' |
    'produtos' |
    'servicos' |
    'trabalhe-conosco' |
    'nossa-unidade' |
    'credenciados';

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 4000, min: 3000},
        items: 1
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};

export default function SecaoBanner({comportamento}: { comportamento: comportamentoSecaoBanner }) {

    type SecaoBannerType = {
        loading: boolean,
        erroAoCarregar: boolean,
        dados: {
            listaDeImagens: Array<string>;
        }
    };

    const [estado, setEstado] = useState<SecaoBannerType>({
        loading: true,
        erroAoCarregar: false,
        dados: {
            listaDeImagens: []
        }
    })

    const caminhoUrl = useLocation();
    let caminhos = caminhoUrl.pathname.split("/");

    let classNameSkleton = comportamento === 'home' ? 'loadingBannerPrincipal' : 'loadingBannerInterno';
    let classNameImagem = comportamento === 'home' ? 'imagemBannerPrincipal' : 'imagemBannerInterno';
    let carroselInfinito = comportamento === 'home' && estado.dados.listaDeImagens.length > 1 ? true : false;

    let dotClass = `dot${estado.dados.listaDeImagens.length}`;

    let titulo = comportamento === 'home' ? false : true;

    const caminhoRaiz: Array<JSX.Element> = [];

    if (comportamento != 'home') {

        caminhos.forEach((caminho: string, index) => {
            if (!FuncoesAuxiliares.verificaStringNumber(caminho)) {
                const pontoInicial = caminhoUrl.pathname.search(caminho);
                const link = caminhoUrl.pathname.slice(0, pontoInicial) + caminho;
                caminhoRaiz.push(
                    <Link key={index} to={link === '' && index === 0 ? '/' : link} className="caminhoRaizLink">
                        <span
                            className='escritaRaizLink'>{FuncoesAuxiliares.formatCaminhoTitulo(caminho === '' && index === 0 ? 'Home' : caminho)}</span>
                        {index != caminhos.length - 1 ? <span className='escritaRaizLink'>{'>'}</span> : null}
                    </Link>
                );
            }
        })

    }

    useEffect(() => {

        if (comportamento === 'home') {
            gestor.getBanners().then(resposta => {

                if (resposta.erro) {

                    estado.erroAoCarregar = true;
                    setEstado({...estado})

                    return;
                }

                estado.dados.listaDeImagens = [bannerRevolucaoRecapagem,];

                resposta.dados?.forEach(imagem => {

                    estado.dados.listaDeImagens?.push(imagem.image);

                });

                estado.loading = false;
                setEstado({...estado});

            });
            return;
        }

        gestor.getBannerInterno(comportamento === 'contato' ? 'fale-conosco' : comportamento).then(resposta => {

            if (resposta.erro) {

                estado.erroAoCarregar = true;
                setEstado({...estado});

                return;
            }

            const listaDeImagens = resposta?.dados?.at(0)?.imagensArray;

            estado.dados.listaDeImagens = [];

            listaDeImagens?.forEach(imagem => {

                estado.dados.listaDeImagens.push(imagem.src);

            });

            estado.loading = false;
            setEstado({...estado});

        })

    }, []);

    //Erro que inibe a renderização, caso não consiga informacoes da api
    if (estado.erroAoCarregar) {
        return null;
    }

    return (
        <section id="SecaoBanner">

            {estado.loading ?
                (
                    <SkeletonTheme baseColor={COLORS.skeletonBaseColor} highlightColor={COLORS.skeletonHighlightColor}>
                        <Skeleton className={classNameSkleton} width="100%"/>
                    </SkeletonTheme>
                )
                :
                estado.dados?.listaDeImagens ?
                    (
                        <Container key={1} fluid className="containerBanner">

                            {titulo ?
                                <Container className='containerTitulo'>
                                    <h2 className='tituloEscrita'>{FuncoesAuxiliares.formatCaminhoTitulo(comportamento)}</h2>
                                    <div className='containerCaminhoRaiz'>
                                        {caminhoRaiz}
                                    </div>
                                </Container>
                                : null}

                            <Carousel className="carrosel" responsive={responsive} showDots={true}
                                      removeArrowOnDeviceType={['tablet', 'mobile']}
                                      autoPlay={true}
                                      autoPlaySpeed={39000}
                                      infinite={carroselInfinito}
                                      dotListClass={dotClass}>
                                {estado.dados.listaDeImagens.map((imagem, index) => {
                                    return <img key={index} className={`imagemBanner ${classNameImagem}`} src={imagem}
                                                alt="imagem banner"></img>
                                })}
                            </Carousel>
                        </Container>
                    ) : null
            }

        </section>
    );
}
