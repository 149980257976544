import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-multi-carousel/lib/styles.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PaginaHome from './paginas/pagina-home/pagina-home';
import PaginaQuemSomos from './paginas/pagina-quem-somos/pagina-quem-somos';
import PaginaNaoEncontrada from './paginas/pagina-nao-encontrada/pagina-nao-encontrada';
import PaginaContato from './paginas/pagina-contato/pagina-contato';
import PaginaBlog from './paginas/pagina-blog/pagina-blog';
import PaginaProdutos from './paginas/pagina-produtos/pagina-produtos';
import PaginaServicos from './paginas/pagina-servicos/pagina-servicos';
import SecaoCabecalho from './componentes/secoes/secao-cabecalho/secao-cabecalho';
import SecaoRodape from './componentes/secoes/secao-rodape/secao-rodape';
import SecaoBlog from './componentes/secoes/secao-blog/secao-blog';
import SecaoPost from './componentes/secoes/secao-post/secao-post';
import SecaoProduto from './componentes/secoes/secao-produto/secao-produto';
import SecaoServicos from './componentes/secoes/secao-servicos/secao-servicos';
import SecaoServico from './componentes/secoes/secao-servico/secao-servico';
import SecaoAcessoRapido from './componentes/secoes/secao-acesso-rapido/secao-acesso-rapido';
import PaginaTrabalheConosco from './paginas/pagina-trabalhe-conosco/pagina-trabalhe-conosco';
import PaginaNossasUnidades from './paginas/pagina-nossas-unidades/pagina-nossas-unidades';
import PaginaCredenciados from './paginas/pagina-credenciados/pagina-credenciados';
import SecaoCategoriasProduto from './componentes/secoes/secao-categorias-produto/secao-categorias-produto';
import SecaoListagemProdutos from './componentes/secoes/secao-listagem-produtos/secao-listagem-produtos';
import SecaoListagemServicos from './componentes/secoes/secao-listagem-servicos/secao-listagem-servicos';
import SecaoProdutosRelacionados from './componentes/secoes/secao-produtos-relacionados/secao-produtos-relacionados';
import { TrackingRoute } from './utilidades/google-analitics';

function App() {

  return (
    <React.StrictMode>
      <Router>
        <SecaoAcessoRapido />
        <SecaoCabecalho />
        <TrackingRoute />
        <Routes>
          <Route path="/" element={<PaginaHome />} />
          <Route path="/institucional" element={<PaginaQuemSomos />} />
          <Route path="/fale-conosco" element={<PaginaContato />} />
          <Route path="/trabalhe-conosco" element={<PaginaTrabalheConosco />} />
          <Route path="/nossa-unidade" element={<PaginaNossasUnidades />} />
          <Route path="/credenciados" element={<PaginaCredenciados />} />
          <Route path="/blog" element={<PaginaBlog />} >
            <Route path="" element={<SecaoBlog comportamento="blog" />} />
            <Route path=":id/*" element={<SecaoPost comportamento="post" />} />
          </Route>
          <Route path="produtos" element={<PaginaProdutos />}>
            <Route path="" element={<SecaoCategoriasProduto comportamento="produtos" />} />
            <Route path=":idCategoria/:nomeCategoria" element={<SecaoListagemProdutos comportamento="produto" />} />
            <Route path=":idCategoria/:nomeCategoria/:idProduto/:nomeProduto" element={[<SecaoProduto key={0} comportamento='produto' />, <SecaoProdutosRelacionados key={1} comportamento='produto' />]} />
          </Route>
          <Route path="servicos" element={<PaginaServicos />}>
            <Route path="" element={<SecaoListagemServicos comportamento="servicos" />} />
            <Route path=":id/*" element={<SecaoServico comportamento='servico' />} />
          </Route>
          <Route path="/*" element={<PaginaHome />} />
          {/* <Route path="/*" element={<PaginaNaoEncontrada />} /> */}
        </Routes>
        <SecaoRodape />
      </Router>
    </React.StrictMode>
  );
}

export default App;
